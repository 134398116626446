import React from "react"
import Layout from "../components/Layout"

export default function Details() {
  return (
    <Layout>
      <div className="content">
        <p>
          <b>RA Friedrich Schweikert</b> ist kein Fachanwalt, der Spezialist
          eines einzigen Rechtsgebietes ist, sondern bietet mit seinem
          Kanzleiteam f&auml;cher&uuml;bergreifendes Wissen an. Seine
          Schwerpunkte sind{" "}
          <b>
            Strafrecht, Arbeitsrecht und Zivilrecht mit Verkehrsrecht
            /Verkehrsunfallrecht und Erbrecht
          </b>
        </p>
        <p>
          Frau Ute Schiefer (Fachwirtin) als erfahrene Kanzleivorsteherin
          namhafter Rechtsanwaltskanzleien und Herr Robert Ramachers (Jurist)
          als ehemaliger Leiter des Schadenb&uuml;ros einer namhaften
          Rechtsschutzversicherung komplettieren die Kanzlei.          
        </p>
        <p>
          Sie k&ouml;nnen somit eine ganzheitliche, geschlossene und in sich
          stimmige Beratung erwarten, ohne dauernd den Anwalt wechseln zu
          m&uuml;ssen.          
        </p>
        <p>
          Rechtsanwalt Friedrich Schweikert gr&uuml;ndete 1996 die Alphatec GbR
          mit und wandelte diese 2000 in eine GmbH um. Schwerpunkte waren v.a.
          dynamische Softwareentwicklung f&uuml;r Anwendungen im Internet. So
          waren Kunden u.a. die Vontobel Bank, mit der an der ersten Online Bank
          gearbeitet wurde, aber auch Versicherungen und weitere Gro&szlig;- und
          Mittelst&auml;ndische Betriebe. Zeitweise arbeiteten elf Personen
          f&uuml;r die Alphatec GmbH.<br/>
          Damit kann Anwalt Schweikert auf einen gro&szlig;en Erfahrungsschatz
          aus dem Betriebsrecht zur&uuml;ckgreifen. Umfasst sind hiervor{" "}
          <b>
            allgemeines Wirtschaftsrecht, Unternehmensrecht,
            Gr&uuml;ndungsrecht, Vertragsrecht, Steuerstrafrecht,
            Wirtschaftsstrafrecht, Arbeitsrecht, Softwarerecht und Internetrecht
          </b>          
        </p>
        <p>
          Im Bereich <b>Arbeitsrecht</b> ber&auml;t und vertritt RA Schweikert
          nicht nur zahlreiche Arbeitgeber und Arbeitnehmer, sondern
          unterst&uuml;tzt auch gr&ouml;&szlig;ere Unternehmen unmittelbar im
          operativen Bereich und bei strategischen Fragen.
        </p>
        <p>
          Im weiteren Schwerpunkt der <b>Strafverteidigung</b> finden Sie einen
          Wahlverteidiger, der nicht nur das N&ouml;tigste erledigt. Blo&szlig;e
          Kenntnisse im Strafgesetz sind hierbei in den wenigsten F&auml;llen
          ausreichend. Ein fundiertes Wissen im Strafprozessualen, ein
          Verhandlungsgeschick beim Deal, eine durchdachte Taktik, Kenntnisse
          von den Besonderheiten f&uuml;r Heranwachsende und im
          Jugendstrafrecht, Verschwiegenheit sowie Kontakte zu den Medien,
          geh&ouml;ren zum modernen Strafprozess dazu. Defizite der
          Ermittlungsbeamten (Polizei und Staatsanwaltschaft) werden durch
          eigene Ermittlungen ausgeglichen und f&uuml;r den Fall, dass die
          Beweislast gegen Sie spricht, wird f&uuml;r ein faires Verfahren und
          gerechtes Ergebnis gek&auml;mpft. Die Honorare eines
          Pflichtverteidigers k&ouml;nnen wir Ihnen hierbei zwar nicht anbieten,
          jedoch bieten wir eine dem Mandat angemessene Verg&uuml;tung.
          <br />
          Gerade im Strafprozess, d&uuml;rfen Kosten nicht die Prozesstaktik
          pr&auml;gen. Auch eine Verurteilung wegen eines geringf&uuml;gigeren
          Vergehens befleckt Ihre Akte und etikettiert Sie im
          Bundeszentralregister als Kriminellen; auch eine Einstellung kann u.U.
          eine Eintragung in den Registern zur Folge haben. Sie sollten daher
          nicht warten, m&ouml;glichst fr&uuml;h einen Strafverteidiger
          aufsuchen, und nicht aus rein finanziellen Gr&uuml;nden haltlose
          Gest&auml;ndnisse ablegen.          
        </p>
        <p>
          Vergessen Sie grds. nicht: Sie haben das Recht zu Schweigen, und
          hiervon sollten Sie ohne einen Anwalt auch Gebrauch machen. Der
          deutsche Strafprozess kennt keine Verurteilung wegen Schweigens und
          der Spruch "Schweigen ist auch eine Antwort" gilt strafprozessual
          nicht!          
        </p>
      </div>
    </Layout>
  )
}
